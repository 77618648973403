import React from 'react'
import Seo from '../components/SEO'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import PageHero from '../components/shared/page-hero'
import PageBody, {
  PageContainer,
  ContentImage,
} from '../components/shared/page-body'
import WeddingSuitsBanner from '../components/banners/wedding-suits'

const WeddingSuitsPage = ({ data }) => (
  <Layout>
    <Seo
      title="Tailored Wedding Suits &amp; Tailscoats"
      description="Made to measure wedding suit tailoring in Bournemouth and Essex."
      pathname="/wedding-suits/"
    />
    <PageHero
      image={data.hero.childImageSharp}
      title="Tailored Wedding Suits &amp; Tailscoats"
    />
    <PageBody>
      <PageContainer maxWidth={840} padded>
        <h1 className="heading--h1">Tailored Wedding Suits</h1>
        <p>
          The growing trend for couples to marry in a much broader range of
          locations and the introduction of the 'Civil Partnership Bill' means a
          more diverse look for weddings is being introduced.
        </p>
        <p>
          With so many different locations available, the wedding 'look' is
          extremely varied and the wide choice enables you to choose exactly
          what’s right for your special occasion.
        </p>
        <ContentImage
          {...data.lightGrey.childImageSharp}
          alt="Tailored Light Grey Wedding Suit"
          style={{ maxHeight: '540px' }}
          imgStyle={{
            objectPosition: '50% 20%',
            fontFamily: 'object-position: 50% 20%',
          }}
        />
        <h2 className="heading--h2">Wedding Suit Styles</h2>
        <p>
          Although the top hat and tailcoat look is still used, the variety of
          styles and looks are immense.
        </p>
        <p>
          For that romantic wedding on those far away sun-kissed luxurious
          islands, the suit is being replaced by bright colours in comfortable
          cottons and linens to fit the environment and jackets being replaced
          by waistcoats.
        </p>
        <p>
          The three-piece suit is still popular but the waistcoats tend to be a
          different fabric from the suit but still co-ordinating with the colour
          theme.
        </p>
        <p>
          Tweeds are very popular for 'Country' weddings with fantastic
          colourful designs to match the colour theme of the wedding.
          The groomsmen are also now matching but still keeping the bride and
          bridegroom the centre of attention.
        </p>
        <ContentImage
          {...data.greyWedding.childImageSharp}
          alt="Tailored Grey Wedding Suit"
          style={{ maxHeight: '540px' }}
          imgStyle={{
            objectPosition: '50% 20%',
            fontFamily: 'object-position: 50% 20%',
          }}
        />
        <ContentImage
          {...data.confetti.childImageSharp}
          alt="Tailored Grey Wedding Suit Confetti"
        />
        <h2 className="heading--h2">Wedding Suit Fit</h2>
        <p>
          With today's emphasis on keeping our bodies in shape, the fit is so
          important. Hire is popular but unfortunately they are made to fit most
          body shapes and therefore to be on the generous side. A Made To
          Measure garment gives you the fit you require. More grooms are having
          their outfit made and the rest of the party being hired. After all it
          is his 'day' as well and should stand out as a couple.
        </p>
      </PageContainer>
    </PageBody>
    <WeddingSuitsBanner />
  </Layout>
)

export default WeddingSuitsPage

export const query = graphql`
  {
    hero: file(relativePath: { eq: "img/wedding-suits-pose.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lightGrey: file(
      relativePath: { eq: "img/grey-check-suit-arch-portrait.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 760, quality: 65) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    greyWedding: file(relativePath: { eq: "img/grey-wedding-suit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 760, quality: 65) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    confetti: file(relativePath: { eq: "img/wedding-suit-confetti.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 760, quality: 65) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
